<template>
	<div>
		<div class="d-flex justify-space-between align-center mb-2 mt-2">
			<div class="input-wrap">
				<h5 class="mb-2">Tarifas</h5>
			</div>
			<div class="action-btn-wrap">
				<v-btn color="accent" outlined class="mr-1" @click.prevent="clickMassive">Masivo</v-btn>
				<v-btn color="accent" @click.prevent="clickCreate">Nuevo</v-btn>
			</div>
		</div>
		<v-divider class="mb-4"></v-divider>
		<v-card color="secondary" class="pa-3">
			<emb-data-table :resource="resource" :columns="columns">
				<tr slot="heading">
					<th>N°</th>
					<th>HABITACIÓN</th>
					<th>PERIODO</th>
					<th class="text-right">PRECIO</th>
					<th class="text-right">ACCIONES</th>
				</tr>
				<tr slot-scope="{ index, row }" :key="index">
					<td>{{ index }}</td>
					<td>{{ row.room }}</td>
					<td>{{ row.period }}</td>
					<td class="text-right">{{ row.price }}</td>
					<td class="text-right">
						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<v-btn
									color="secondary"
									class="ma-1"
									fab
									x-small
									dark
									@click.prevent="clickCreate(row.id)"
									v-on="on"
								>
									<v-icon>mdi-pencil</v-icon>
								</v-btn>
							</template>
							<span>Modificar</span>
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<v-btn
									color="accent"
									class="ma-1"
									fab
									x-small
									dark
									@click.prevent="clickDelete(row.id)"
									v-on="on"
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</template>
							<span>Eliminar</span>
						</v-tooltip>
					</td>
				</tr>
			</emb-data-table>
		</v-card>
		
		<table-form 
			:showDialog.sync="showDialog" 
			:recordId="recordId"
		></table-form>
		
		<massive-form 
			:showDialog.sync="showMassiveDialog"
		></massive-form>
	</div>
</template>

<script>
import { deletable } from 'Mixins/deletable';
import TableForm from 'Views/Admin/Rates/Form.vue';
import MassiveForm from 'Views/Admin/Rates/Massive.vue';

export default {
	data() {
		return {
			showDialog: false,
			showMassiveDialog: false,
			resource: 'rates',
			recordId: null,
			records: [],
			columns: {
				name: 'Nombre',
			},
		};
	},
	mixins: [deletable],
	components: {
		TableForm,
		MassiveForm,
	},
	
	methods: {
		clickMassive() {
			this.showMassiveDialog = true;
		},
		clickCreate(recordId = null) {
			this.recordId = recordId;
			this.showDialog = true;
		},
		clickDelete(id) {
			this.destroy(`/${this.resource}/${id}`).then(() =>
				this.$eventHub.$emit('reloadData', this.resource)
			);
		},
	},
};
</script>
