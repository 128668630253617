<template>
	<div>
		<el-dialog
			:title="titleDialog"
			:visible="showDialog"
			@close="close"
			@open="create"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			width="45%"
		>
			<form autocomplete="off" @submit.prevent="submit">
				<v-row>
					<!-- <v-col cols="12" xl="12">
						<div class="form-group" :class="{ 'error--text': errors.room_type_id }">
							<label>Tipo de habitación</label>
							<el-select v-model="form.room_type_id" dusk="room_type_id">
								<el-option
									v-for="option in room_types"
									:key="option.id"
									:value="option.id"
									:label="option.name"
								></el-option>
							</el-select>
							<small
								class="error--text"
								v-if="errors.room_type_id"
								v-text="errors.room_type_id[0]"
							></small>
						</div>
					</v-col> -->
					
					<v-col cols="12" xl="12">
						<div class="form-group" :class="{ 'error--text': errors.room_id }">
							<label>Habitación</label>
							<el-select v-model="form.room_id" dusk="room_id" filterable>
								<el-option
									v-for="option in rooms"
									:key="option.id"
									:value="option.id"
									:label="option.name"
								></el-option>
							</el-select>
							<small
								class="error--text"
								v-if="errors.room_id"
								v-text="errors.room_id[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="12">
						<div class="form-group" :class="{ 'error--text': errors.period_id }">
							<label>Periodo</label>
							<el-select v-model="form.period_id" dusk="period_id">
								<el-option
									v-for="option in periods"
									:key="option.id"
									:value="option.id"
									:label="option.name"
								></el-option>
							</el-select>
							<small
								class="error--text"
								v-if="errors.period_id"
								v-text="errors.period_id[0]"
							></small>
						</div>
					</v-col>
					<!-- <v-col cols="12" xl="12">
						<div class="form-group" :class="{ 'error--text': errors.name }">
							<label>Nombre</label>
							<el-input v-model="form.name"></el-input>
							<small
								class="error--text"
								v-if="errors.name"
								v-text="errors.name[0]"
							></small>
						</div>
					</v-col> -->
					<v-col cols="12" xl="12">
						<div class="form-group" :class="{ 'error--text': errors.price }">
							<label>Precio</label>
							<el-input-number
								v-model="form.price"
								:min="1"
								:precision="2" 
								:step="0.1"
								controls-position="right"
								:disabled="permission ? false : true"
							></el-input-number>
							<small
								class="error--text"
								v-if="errors.price"
								v-text="errors.price[0]"
							></small>
						</div>
					</v-col>
				</v-row>
				<div class="form-actions text-right mt-3 mb-3">
					<el-button type="danger" @click.prevent="close()">Cancelar</el-button>
					<el-button type="primary" native-type="submit" :loading="loading">
						<template v-if="loading">
							Guardando...
						</template>
						<template v-else>
							Guardar
						</template>
					</el-button>
				</div>
			</form>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: ['showDialog', 'recordId'],
	data() {
		return {
			loading: false,
			titleDialog: null,
			resource: 'rates',
			valid: false,
			room_types: [],
			periods: [],
			rooms: [],
			errors: {},
			form: {},
			permission: false,
		};
	},
	created() {},
	methods: {
		initForm() {
			this.errors = {};
			this.form = {
				id: null,
				room_type_id: null,
				period_id: null,
				rate_id: null,
				name: null,
				price: 1,
			};
		},
		async create() {
			this.titleDialog = this.recordId ? 'Editar tarifa' : 'Nueva tarifa';
			await this.initForm();
			await this.getTables();

			if (this.recordId) {
				this.$http.get(`/${this.resource}/record/${this.recordId}`).then((response) => {
					this.form = response.data.data;
				});
			}
		},
		getTables() {
			this.$http.get(`/${this.resource}/tables`).then((response) => {
				this.room_types = response.data.room_types;
				this.periods = response.data.periods;
				this.rooms = response.data.rooms;
				this.permission = response.data.permission;

				this.form.room_type_id = this.room_types.length > 0 ? this.room_types[0].id : null;
			});
		},
		submit() {
			this.loading = true;
			this.$http
				.post(`/${this.resource}`, this.form)
				.then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
						this.$eventHub.$emit('reloadData', this.resource);
						if (this.form.id) {
							this.close();
						}
						this.initForm();
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					if (error.response.status === 422) {
						this.errors = error.response.data.errors;
					} else {
						this.$message.error(error.response.data.message);
					}
				})
				.then(() => {
					this.loading = false;
				});
		},
		close() {
			this.$emit('update:showDialog', false);
			this.initForm();
		},
	},
};
</script>
